/* SLIDE UP */
const slideUp = (target, duration = 500) => {
  target.style.transitionProperty = `height, margin, padding`
  target.style.transitionDuration = duration + `ms`
  target.style.boxSizing = `border-box`
  target.style.height = target.offsetHeight + `px`
  target.offsetHeight
  target.style.overflow = `hidden`
  target.style.height = 0
  target.style.paddingTop = 0
  target.style.paddingBottom = 0
  target.style.marginTop = 0
  target.style.marginBottom = 0
  window.setTimeout(() => {
    target.style.display = `none`
    target.style.removeProperty(`height`)
    target.style.removeProperty(`padding-top`)
    target.style.removeProperty(`padding-bottom`)
    target.style.removeProperty(`margin-top`)
    target.style.removeProperty(`margin-bottom`)
    target.style.removeProperty(`overflow`)
    target.style.removeProperty(`transition-duration`)
    target.style.removeProperty(`transition-property`)
  }, duration)
}

/* SLIDE DOWN */
const slideDown = (target, duration = 500) => {
  target.style.removeProperty(`display`)
  let display = window.getComputedStyle(target).display
  if (display === `none`) {
    display = `block`
  }
  target.style.display = display
  const height = target.offsetHeight
  target.style.overflow = `hidden`
  target.style.height = 0
  target.style.paddingTop = 0
  target.style.paddingBottom = 0
  target.style.marginTop = 0
  target.style.marginBottom = 0
  target.offsetHeight
  target.style.boxSizing = `border-box`
  target.style.transitionProperty = `height, margin, padding`
  target.style.transitionDuration = duration + `ms`
  target.style.height = height + `px`
  target.style.removeProperty(`padding-top`)
  target.style.removeProperty(`padding-bottom`)
  target.style.removeProperty(`margin-top`)
  target.style.removeProperty(`margin-bottom`)
  window.setTimeout(() => {
    target.style.removeProperty(`height`)
    target.style.removeProperty(`overflow`)
    target.style.removeProperty(`transition-duration`)
    target.style.removeProperty(`transition-property`)
  }, duration)
}

/* TOOGGLE */
const slideToggle = (target, duration = 500) => {
  if (window.getComputedStyle(target).display === `none`) {
    return slideDown(target, duration)
  } else {
    return slideUp(target, duration)
  }
}

(function ($) {
  $(document).ready(function () {
    svg4everybody({})
  })
})(jQuery)

// set vh height
window.addEventListener("resize",function(){
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
})
// set vh height end

window.addEventListener("load",function(){
  let body = this.document.querySelector("body");
  let html = this.document.querySelector("html");

  setTimeout(function(){
    body.style.opacity = 1
  },500)
  
  // set vh height
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  // set vh height end

  // SELECT ===================================================
  function closeSelect() {
    selects.forEach(item => {
      if (item.classList.contains(('active'))) {
        item.classList.remove('active')
      }
    })
    dropDowns.forEach(item => {
      if (item.classList.contains(('active'))) {
        item.classList.remove('active')
        slideUp(item, 100)
      }
    })
  }
  const selectsInside = document.querySelector('.selects-inside')
  const selectTrigger = document.querySelectorAll('.select__trigger')
  const selects = document.querySelectorAll('.selecter')
  const dropDowns = document.querySelectorAll('.select__dropdown')
  const dropDownOption = document.querySelectorAll('.select__option')
  selectTrigger && selectTrigger.forEach(item => {
    item.addEventListener('click', function () {
      const container = this.closest('.selecter')
      const dropdown = container.querySelector('.select__dropdown')
      const selectsItems = [...selects].filter(item => item !== container)
      const dropDownsItems = [...dropDowns].filter(item => item !== dropdown)
      selectsItems.forEach(item => {
        item.classList.remove('active')
      })
      dropDownsItems.forEach(item => {
        item.classList.remove('active')
        slideUp(item, 100)
      })
      // closeSelect()
      if (!container.classList.contains('active')) {
        container.classList.add('active')
        dropdown && dropdown.classList.add('active')
        slideDown(dropdown, 100)
      } else {
        container.classList.remove('active')
        dropdown && dropdown.classList.remove('active')
        slideUp(dropdown, 100)
      }
    })
  })

  selectsInside && selectsInside.addEventListener('click', function (e) {
    const target = e.target
    if (!target.closest('.selecter')) {
      closeSelect()
    }
  })

  dropDownOption && dropDownOption.forEach(item => {
    item.addEventListener('click', function () {
      const container = this.closest('.selecter')
      const valueItem = container.querySelector('.select__value')
      const input = container.querySelector('.select__input')
      const options = container.querySelectorAll('.select__option')
      const formItem = this.closest('.form-item')
      const trigger = container.querySelector('.select__trigger')

      if (trigger.classList.contains('placeholder')) {
        trigger.classList.remove('placeholder')
      }

      if (formItem) {
        if (formItem.classList.contains('error')) {
          formItem.classList.remove('error')
        }
      }
      const value = this.getAttribute('data-value')
      if (valueItem) {
        valueItem.innerHTML = value
      }
      input.value = value
      options.forEach(item => {
        item.classList.remove('active')
      })
      this.classList.add('active')
      closeSelect()
    })
  })
  // END SELECT ===================================================

  // move plag img ================================================
  let movementStrength = 10;
  let height = movementStrength / window.innerHeight;
  let width = movementStrength / window.innerWidth;
  let moveImg = this.document.querySelector(".plug-photo__move  img.plug-photo__img")
  window.addEventListener("mousemove",function(e){
    if(window.innerWidth > 1024){
      let pageX = e.pageX - (window.innerWidth / 2);
      let pageY = e.pageY - (window.innerHeight / 2);
      let newvalueX = width * pageX * -1 - 10;
      let newvalueY = height * pageY * -1 - 10;
      if(moveImg){
        moveImg.style.transform = `translate(${newvalueX + "px"}, ${newvalueY + "px"})`
      }
    }
    
  })
  // move plag end img ================================================

})